import logger from 'utils/logger';

import { ProductFormInputs } from './../models/purchaseForm.model';
import useAuth from 'contexts/AuthContext';
import { Sku } from 'models/sku.model';
import { useRouter } from 'next/router';
import { State as CardCustomisationState } from 'reducers/CardCustomisationReducer';
import Cookies from 'js-cookie';

import {
  ProductViewedPayload,
  SegmentContext,
  CustomElementInteractionPayload,
  CheckoutStepCompletedPayload,
  CheckoutStepViewedPayload,
  ProductAddedPayload,
  BaseSegmentContext,
  CouponAppliedPayload,
  CouponDeniedPayload,
  GifSearchedPayload,
  CouponEnteredPayload,
  OrderCompletedPayload,
  OrderFailedPayload,
  ProductRemovedPayload,
  CouponRemovedPayload,
  CustomFilterSortAppliedPayload,
  ProductsSearchedPayload,
  SignedInPayload,
  SignedUpPayload,
  ProductClickedPayload,
  BusinessLeadSubmitted,
} from 'models/segment.model';
import { parseUserAgent } from 'react-device-detect';
import { EMPTY_STRING } from 'common/utils/string';

type PaymentMethodId = 'apple_pay' | 'credit_card' | 'google_pay' | 'paypal';

/**
 * Handles all segment events.
 */
const useSegment = () => {
  const router = useRouter();
  const { user } = useAuth();

  const deviceInfo = parseUserAgent(
    typeof window !== 'undefined' ? window.navigator.userAgent : 'Prezzee Frontend 2.0 (FWKNqc0E)'
  );
  const deviceType = deviceInfo?.device.type || 'desktop';
  const deviceName = `${deviceType} ${deviceInfo?.os.name}`;
  const isPb = router.pathname.startsWith('/business');
  const getGaClientId = () => {
    const id = Cookies.get('_ga') || EMPTY_STRING;
    return id.slice(6);
  };

  /**
   * @private Adds base context to all segment event calls.
   * @param payload The payload of the event.
   * @param event The name of the event.
   * @param version The event version.
   */
  const trackWithContext = <PayloadType>(payload: PayloadType, event: string, version?: number) => {
    const baseContext: BaseSegmentContext = {
      store: isPb ? 'business' : 'consumer',
      server_region: process.env.NEXT_PUBLIC_APP_COUNTRY_CODE,
      traits: user ? { email: user?.email, phone: user?.phoneNumber } : {},
    };
    const context: SegmentContext = {
      ...baseContext,
      device: {
        name: deviceName,
        type: deviceType,
      },
    };

    if (version) {
      context.protocols = {
        event_version: version,
      };
    }

    const payloadWithBaseContext: PayloadType & BaseSegmentContext = {
      ...payload,
      ...baseContext,
    };

    try {
      analytics.track(event, payloadWithBaseContext, {
        context,
      });
    } catch (error) {
      logger.error(
        {
          err: error,
          source: 'External API - Segment',
        },
        'error: unable to forward to segment'
      );
    }
  };

  /**
   * @private Adds base context to all segment event calls.
   * @param payload The payload of the event.
   * @param event The name of the event.
   * @param version The event version.
   */
  const trackWithContextAsync = async <PayloadType>(payload: PayloadType, event: string, version?: number) => {
    const baseContext: BaseSegmentContext = {
      store: isPb ? 'business' : 'consumer',
      server_region: process.env.NEXT_PUBLIC_APP_COUNTRY_CODE,
      traits: user ? { email: user?.email, phone: user?.phoneNumber } : {},
    };
    const context: SegmentContext = {
      ...baseContext,
      device: {
        name: deviceName,
        type: deviceType,
      },
    };

    if (version) {
      context.protocols = {
        event_version: version,
      };
    }

    const payloadWithBaseContext: PayloadType & BaseSegmentContext = {
      ...payload,
      ...baseContext,
    };

    try {
      await analytics.track(event, payloadWithBaseContext, {
        context,
      });
    } catch (error) {
      logger.error(
        {
          err: error,
          source: 'External API - Segment',
        },
        'error: unable to forward to segment'
      );
    }
  };

  /**
   * @public Tracks a product viewed event.
   * @param sku The Sku that was viewed.
   * @param purchaseInstanceId The generated purchase instance ID of the viewed product.
   */
  const productViewed = (sku: Sku, purchaseInstanceId: string) => {
    const payload: ProductViewedPayload = {
      categories: sku.categories.map(category => category.name),
      category: sku.categories?.[0]?.name || '',
      image_url: sku.themes?.[0]?.image || '',
      name: sku.displayName,
      nonInteraction: 1,
      product_id: sku.uid,
      purchase_instance_id: purchaseInstanceId,
      quantity: 1,
      sku: sku.slug,
      ga_clientid: getGaClientId(),
    };

    return trackWithContext<ProductViewedPayload>(payload, 'Product Viewed', 2);
  };

  /**
   * @public Tracks coupon/promo entered event
   * @param payload The payload of the event.
   */
  const couponEntered = (payload: CouponEnteredPayload) => {
    return trackWithContext<CouponEnteredPayload & { user_type: 'business' | 'consumer' }>(
      { ...payload, user_type: 'consumer' },
      'Coupon Entered'
    );
  };

  /**
   * @public Tracks coupon/promo removed event
   * @param payload The payload of the event.
   */
  const couponRemoved = (payload: CouponRemovedPayload) => {
    return trackWithContext<CouponRemovedPayload & { user_type: 'business' | 'consumer' }>(
      { ...payload, user_type: 'consumer' },
      'Coupon Removed'
    );
  };

  /**
   * @public Tracks coupon/promo applied event
   * @param payload The payload of the event.
   */
  const couponApplied = (payload: CouponAppliedPayload) => {
    return trackWithContext<CouponAppliedPayload>(payload, 'Coupon Applied');
  };

  /**
   * @public Tracks coupon/promo applied event
   * @param payload The payload of the event.
   */
  const couponDenied = (payload: CouponDeniedPayload) => {
    return trackWithContext<CouponDeniedPayload>(payload, 'Coupon Denied');
  };

  /**
   * @public Tracks a product clicked event
   * @param payload The payload of the event.
   */
  const productClicked = (payload: ProductClickedPayload) => {
    return trackWithContext<ProductClickedPayload>({ ...payload, ga_clientid: getGaClientId() }, 'Product Clicked', 2);
  };

  /**
   * @public Tracks a product added event
   * @param payload The payload of the event.
   */
  const productAdded = (payload: ProductAddedPayload) => {
    return trackWithContext<ProductAddedPayload>(
      { ...payload, url: window.location.href, ga_clientid: getGaClientId() },
      'Product Added',
      2
    );
  };

  /**
   * @public Tracks a product added event
   * @param payload The payload of the event.
   */
  const productRemoved = (payload: ProductRemovedPayload) => {
    return trackWithContext<ProductRemovedPayload>(payload, 'Product Removed', 2);
  };

  /**
   * @public Tracks a search of products event
   * @param payload The payload of the event.
   */
  const productsSearched = (payload: ProductsSearchedPayload) => {
    return trackWithContext<ProductsSearchedPayload>({ ...payload, ga_clientid: getGaClientId() }, 'Products Searched');
  };

  /**
   * @public Tracks a checkout step completed event (ie. pick/wrap/deliver/pay section).
   * @param payload The payload of the event.
   */
  const checkoutStepCompleted = (payload: CheckoutStepCompletedPayload) => {
    return trackWithContext<CheckoutStepCompletedPayload>(payload, 'Checkout Step Completed', 2);
  };

  /**
   * @public Tracks a custom element interaction event (ie. purchase type selected - myself/for someone else)
   * @param payload The payload of the event.
   */
  const customElementInteraction = (payload: CustomElementInteractionPayload) => {
    return trackWithContext<CustomElementInteractionPayload>(
      {
        ...payload,
        ga_clientid: getGaClientId(),
      },
      'Custom Element Interaction'
    );
  };

  /**
   * @public Tracks a custom element interaction event (ie. purchase type selected - myself/for someone else)
   * @param payload The payload of the event.
   */
  const filterSortApplied = (payload: CustomFilterSortAppliedPayload) => {
    return trackWithContext<CustomFilterSortAppliedPayload>(payload, 'Filter Sort Applied');
  };

  /**
   * @public Tracks a custom element interaction event (ie. purchase type selected - myself/for someone else)
   * @param payload The payload of the event.
   */
  const customElementInteractionAsync = (payload: CustomElementInteractionPayload) => {
    return trackWithContextAsync<CustomElementInteractionPayload>(
      { ...payload, ga_clientid: getGaClientId() },
      'Custom Element Interaction'
    );
  };

  /**
   * @public Tracks a checkout step viewed event (ie. pick/wrap/deliver/pay section).
   * @param payload The payload of the event.
   */
  const checkoutStepViewed = (payload: CheckoutStepViewedPayload) => {
    return trackWithContext<CheckoutStepViewedPayload>(payload, 'Checkout Step Viewed');
  };

  /**
   * @public Tracks a completed order event after the user pays
   * @param payload The payload of the event.
   */
  const orderCompleted = (payload: OrderCompletedPayload) => {
    return trackWithContext<OrderCompletedPayload & { affiliation: 'business' | 'consumer' }>(
      {
        ...payload,
        affiliation: isPb ? 'business' : 'consumer',
        ga_clientid: getGaClientId(),
      },
      'Order Completed',
      3
    );
  };

  /**
   * @public Tracks a failed order event
   * @param payload The payload of the event.
   */
  const orderFailed = (
    sku: Sku,
    purchaseInstanceId: string,
    formValues: ProductFormInputs,
    cardCustomisationState: CardCustomisationState,
    failReason: string,
    total: number,
    paymentMethodId?: PaymentMethodId,
    order?: any //optional as order may not exist yet
  ) => {
    const customTheme = cardCustomisationState?.customThemeDesigns?.[0]?.image;

    const selectedCardDesign =
      cardCustomisationState?.selectedCardDesign?.isTemplate && customTheme
        ? customTheme
        : cardCustomisationState?.selectedCardDesign?.imageUrl;

    const payload = {
      affiliation: isPb ? 'business' : 'consumer',
      buy_for: formValues?.purchaseType,
      coupon: formValues?.promo?.promoCode || '',
      currency: process.env.NEXT_PUBLIC_APP_CURRENCY_CODE,
      delivery_method: formValues?.scheduledDeliveryAt ? 'scheduled' : 'now',
      fail_reason: failReason,
      order_number: order?.orderNumber || '',
      payment_method: paymentMethodId || '',
      purchase_instance_id: purchaseInstanceId,
      total: total,
      video_message: formValues?.giftMedia?.videoId ? true : false,
      recipient_count: formValues.purchaseType === 'friend' ? formValues?.recipients?.length || 1 : 1,
      products: [
        {
          categories: sku?.categories.map(categories => categories.name),
          category: sku?.categories.length > 0 && sku?.categories[0].name,
          image_url: selectedCardDesign || sku?.themes?.[0]?.image,
          name: sku?.displayName || '',
          price: parseFloat(formValues?.value),
          product_id: sku?.uid,
          quantity: 1,
          sku: sku?.slug || '',
          url: window.location.href,
        },
      ],
    };

    return trackWithContext<OrderFailedPayload>(payload, 'Order Failed', 2);
  };

  /**
   * @public Tracks a gifs searched event.
   * @param payload The payload of the event.
   */
  const gifSearched = (payload: GifSearchedPayload) => {
    return trackWithContext<GifSearchedPayload>(payload, 'Gif Searched');
  };

  /**
   * @public Tracks a sign in event.
   * @param payload The payload of the event.
   */
  const signedIn = (payload: SignedInPayload) => {
    return trackWithContext<SignedInPayload>({ ...payload, ga_clientid: getGaClientId() }, 'Signed In');
  };

  /**
   * @public Tracks a sign up event.
   * @param payload The payload of the event.
   */
  const signedUp = (payload: SignedUpPayload) => {
    return trackWithContext<SignedUpPayload>({ ...payload, ga_clientid: getGaClientId() }, 'Signed Up');
  };

  /**
   * @public Tracks a lead submitted event.
   * @param payload The payload of the event.
   */
  const businessLeadSubmitted = (payload: BusinessLeadSubmitted) => {
    return trackWithContext<BusinessLeadSubmitted>({ ...payload, ga_clientid: getGaClientId() }, 'Lead Submitted');
  };

  return {
    productViewed,
    checkoutStepCompleted,
    customElementInteraction,
    customElementInteractionAsync,
    checkoutStepViewed,
    productAdded,
    productRemoved,
    productsSearched,
    orderCompleted,
    orderFailed,
    couponApplied,
    couponDenied,
    gifSearched,
    couponEntered,
    couponRemoved,
    signedIn,
    signedUp,
    filterSortApplied,
    productClicked,
    businessLeadSubmitted,
  };
};

export default useSegment;
