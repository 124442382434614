// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PhoneNumberUtils from "./phoneNumberUtils";

var isValidPhoneNumber = PhoneNumberUtils.validateMobileNumber;

var parsePhoneNumber = PhoneNumberUtils.toPhoneInputFormat;

var getCountry = PhoneNumberUtils.getCountryFromNumber;

export {
  isValidPhoneNumber ,
  parsePhoneNumber ,
  getCountry ,
}
/* isValidPhoneNumber Not a pure module */
