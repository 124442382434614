import parsePhoneNumber from 'libphonenumber-js';
import getCountry from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const validateMobileNumber = (countryCode, mobileNumber) => {
  if (!mobileNumber || !mobileNumber.trim()) {
    return true;
  }

  return isValidPhoneNumber(mobileNumber.replaceAll(/\s/g, ''));
};

const defaultPhoneInputValue = {
  countryCode: (process.env.NEXT_PUBLIC_APP_COUNTRY_CODE || '').toLocaleLowerCase(),
  number: '',
};

export const getCountryFromNumber = number => {
  return getCountry(number);
};

export const toPhoneInputFormat = number => {
  if (!number || !number.trim()) {
    return defaultPhoneInputValue;
  }
  const res = parsePhoneNumber(number, {
    extract: false,
  });
  if (!res) {
    return defaultPhoneInputValue;
  }
  return {
    countryCode: (res.country || '').toLocaleLowerCase(),
    number: res.number,
  };
};
