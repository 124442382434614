import { css } from '@emotion/css';
import { Dialog, Row, Col, Typography, TextField, Button } from '@prezzee/components';
import { colors, spacing } from '@prezzee/tokens';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import useAuth from 'contexts/AuthContext';

const style = {
  container: css`
    padding: ${spacing.spacing14} ${spacing.spacing08};
    text-align: center;

    & > * {
      margin-top: ${spacing.spacing05};
    }

    h4 {
      color: ${colors.red400};
    }
  `,
  form: css`
    & > * {
      margin-top: ${spacing.spacing05};
    }
  `,
  input: css`
    width: 75%;
  `,
  helperText: css`
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  `,
};

type MfaDialogProps = {
  onSubmit: (mfaCode: string) => void;
  onSignOut: () => void;
};

const MfaDialog = ({ onSubmit, onSignOut }: MfaDialogProps) => {
  const { error } = useAuth();
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const _onSubmit = ({ mfaCode }) => {
    if (onSubmit) {
      onSubmit(mfaCode);
    }

    form.reset();
  };

  return (
    <Dialog open aria-labelledby="mfa-dialog-title" aria-describedby="mfa-dialog-description">
      <div className={style.container}>
        <Row align="center">
          <Col xs={12}>
            <Typography id="mfa-dialog-title" variant="h4">
              Security Code
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography id="mfa-dialog-description">
              Please enter the security code we&apos;ve just sent to you via SMS
            </Typography>
          </Col>
        </Row>
        <form onSubmit={form.handleSubmit(_onSubmit)} className={style.form}>
          <Row>
            <Col>
              <TextField
                className={style.input}
                name="mfaCode"
                label="Security code"
                error={form.errors.mfaCode?.message || error?.message}
                inputRef={form.register({
                  required: {
                    value: true,
                    message: 'Please enter a security code.',
                  },
                  pattern: {
                    value: new RegExp('^(\\s*\\d{6}\\s*)(,\\s*\\d{6}\\s*)*,?\\s*$'),
                    message: 'Please enter a valid security code.',
                  },
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit">Validate</Button>
            </Col>
          </Row>
        </form>
        <Row>
          <Col>
            <Link href="/logout" prefetch={false} passHref>
              <Button variant="text" onClick={() => onSignOut && onSignOut()}>
                Sign out
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography variant="body2" className={style.helperText}>
              Need help? Please contact us at{' '}
              <a href="mailto:help@prezzee.com?subject=Issue with 2FA on website browser">help@prezzee.com</a>
            </Typography>
          </Col>
        </Row>
      </div>
    </Dialog>
  );
};

export default MfaDialog;
