import { SERVER_FRAUD_ERROR } from 'common/constants';

/**
 * A middleware function for handling generic errors in queries.
 * @param response HTTP Response object that needs error handling.
 * @returns Throws new error if passed HTTP response failed else returns response.
 */
const handleErrors = async (response: Response) => {
  const isJsonContentType = response.headers.get('Content-Type') === 'application/json';
  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 204:
      if (isJsonContentType) {
        return response.json();
      } else {
        return response;
      }

    case 401:
      if (isJsonContentType) {
        const err = await response.json();
        throw new Error(err.detail);
      } else {
        throw new Error('Unauthorized Error');
      }

    case 460:
      throw new Error('Server MFA Required Error');
    case 461:
      throw new Error(SERVER_FRAUD_ERROR);
    default:
      // Server returns custom errors through error codes 400 - 500 via json.detail
      if (response.status >= 400 && response.status < 500 && isJsonContentType) {
        return response.json().then(json => {
          // Check if error type over detail message
          if (json.error_type) {
            throw new Error(json.error_type);
          } else if (json.detail) {
            throw new Error(json.detail);
          }
          throw new Error('Could not decode error from the server');
        });
      } else {
        throw new Error('Network Error ' + response.status);
      }
  }
};

export default handleErrors;
