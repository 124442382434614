export type WebConfig = {
  featureStripeBillingAddress: boolean;
  featureBraintreePaymentOptionGuest: boolean;
  featureBraintreePaymentOptionUser: boolean;
  featureGuestCheckout: boolean;
  senderAliases: Array<string>;
  senderTitle: string;
  senderSubtitle: string;
  defaultPcPurchaseFlow: 'old' | 'new';
  featureClassicSwitchForGif: boolean;
  classicMultiPurchaseMaxRecipient: number;
  multiPurchaseToggle: MultiPurchaseToggle;
  unwrappingAuthFlowV2: boolean;
  featureEnhancedWalletWeb: boolean;
  featureBffGuestCheckout: boolean;
  featureBffNewsletter: boolean;
  defaultGiphySearchTerm: string;
  featureLayer: boolean;
  featureLayerForClassic: boolean;
  featureLayerBuyingWeb: boolean;
  featureBffFraud: boolean;
  featureEmailTypoDetection: boolean;
};

export enum MultiPurchaseToggle {
  False = 'False',
  MemberGuest = 'member_and_all_guest',
  Member = 'member',
  Guest = 'all_guest',
}

// Want to remove response types and only have decoded types
export type WebConfigResponse = {
  feature_scheduled_delivery: boolean;
  feature_partial_balance_enabled: boolean;
  default_denomination: string;
  max_items_per_swap_order: number;
  feature_braintree_payment_option_guest: boolean;
  feature_braintree_payment_option_member: boolean;
  feature_stripe_billing_address: boolean;
  gift_sender_aliases: Array<string>;
  gift_sender_title?: string;
  gift_sender_subtitle?: string;
  feature_guest_checkout: boolean;
  feature_classic_multi_purchase_toggle: MultiPurchaseToggle;
  feature_sms_delivery: boolean;
  feature_refer_a_friend: boolean;
  feature_refer_a_friend_banner: boolean;
  default_newsletter_optin: boolean;
  default_pc_purchase_flow: 'old' | 'new';
  feature_classic_switch_for_gif: boolean;
  classic_multi_purchase_max_recipient: string;
  unwrapping_auth_flow_v2: boolean;
  feature_enhanced_wallet_web: boolean;
  feature_bff_guest_checkout: boolean;
  feature_bff_newsletter: boolean;
  default_giphy_search_term: string;
  feature_layer: boolean;
  feature_layer_for_classic: boolean;
  feature_layer_buying_web: boolean;
  feature_bff_fraud: boolean;
  feature_email_typo_detection: boolean;
};
